import * as React from 'react';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { Box } from '../box';
import type { BadgeWrapT } from './BadgeWrap.types';
import { variants, type BadgeWrapVariantProps } from './BadgeWrap.variants';

export const BadgeWrap = ({
  children,
  badge,
  compact,
  placement,
  offset = true,
  className,
  ...props
}: BadgeWrapT) => {
  let transform: BadgeWrapVariantProps['transform'] = 'none';
  if (offset) {
    if (placement !== undefined) {
      transform = placement;
    } else {
      transform = compact ? 'compact' : 3;
    }
  }

  return (
    <Box {...props} className={clsx('overflow-visible', className)}>
      <Box className={variants({ transform })}>{badge}</Box>
      {children}
    </Box>
  );
};
