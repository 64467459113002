import React, { useState } from 'react';
import { UserIcon } from '@mentimeter/ragnar-visuals';
import { clsx } from '@mentimeter/ragnar-tailwind-config';
import { gray1300 } from '@mentimeter/ragnar-colors';
import { getFontSize } from '@mentimeter/ragnar-system';
import { useRagnar } from '@mentimeter/ragnar-react';
import { Image } from '../image';
import { Text } from '../text';
import { Box } from '../box';
import { variants, textVariants } from './Avatar.variants';
import { getAvatarColor } from './Avatar.utils';
import { type AvatarT } from './Avatar.types';

export const AvatarCircle = ({
  itemId,
  name,
  initials = '',
  icon,
  badge,
  profilePictureUrl,
  size = 'default',
  fontSize,
  'aria-label': ariaLabel,
  disabled = false,
  className,
  style,
  ...rest
}: AvatarT) => {
  const [profilePictureFailedToLoad, setProfilePictureFailedToLoad] =
    useState(false);

  const defaultClasses = [
    'flex',
    'items-center',
    'justify-center',
    'rounded-full',
    'overflow-hidden',
  ];

  const variantClasses = variants({ size });
  const textVariantClasses = textVariants({ size });
  const bgClass = disabled ? 'bg-disabled-strong' : getAvatarColor(itemId);
  const { theme } = useRagnar();

  const renderContent = () => {
    if (profilePictureUrl && !profilePictureFailedToLoad) {
      return (
        <Image
          src={profilePictureUrl}
          alt={name ? `Profile picture for ${name}` : 'Profile picture'}
          onError={() => {
            setProfilePictureFailedToLoad(true);
          }}
          className={clsx('w-full', disabled && 'opacity-40')}
        />
      );
    }

    if (initials !== '') {
      return (
        <Text
          className={clsx(
            'text-size-inherit',
            disabled ? 'text-on-disabled-strong' : 'text-black-base',
          )}
          role="img"
          aria-label={`User initials, ${initials}`}
        >
          {initials}
        </Text>
      );
    }

    if (icon) {
      return <Box>{icon}</Box>;
    }

    return (
      <UserIcon size={1} color={disabled ? 'onDisabledStrong' : gray1300} />
    );
  };

  return (
    <Box
      aria-label={ariaLabel}
      className={clsx(
        defaultClasses,
        variantClasses,
        textVariantClasses,
        bgClass,
        className,
        'light', // force light mode
      )}
      {...rest}
      // this style can be removed one the fontSize prop has been migrated to className
      // remember to remove ragnar-system depepndency once this is done also
      style={{
        ...style,
        ...(fontSize && {
          fontSize:
            typeof fontSize === 'string' || typeof fontSize === 'number'
              ? getFontSize(fontSize, theme.kosmosFontSize)
              : undefined,
        }),
      }}
    >
      {renderContent()}
    </Box>
  );
};
