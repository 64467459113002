const AVATAR_COLORS = [
  'bg-[var(--palette-blue-100)]',
  'bg-[var(--palette-coral-200)]',
  'bg-[var(--palette-sand-200)]',
  'bg-[var(--palette-green-200)]',
  'bg-[var(--palette-purple-300)]',
];

/** Takes a string and hashes it into an integer value. */
function getIntHashFromString(value: string) {
  const randomInt = value
    .split('')
    .reduce(
      (hashCode: number, currentVal: string) =>
        currentVal.charCodeAt(0) +
        (hashCode << 6) +
        (hashCode << 16) -
        hashCode,
      0,
    );

  // Ensure the integer is positive
  return Math.abs(randomInt);
}

export function getAvatarColor(avatarId = '1') {
  const randomInt = getIntHashFromString(avatarId);
  return AVATAR_COLORS[randomInt % AVATAR_COLORS.length]!;
}

// This method summarizes a number with a metric suffix
// Ex: 27364 => 27k
export const getSummarizeDisplayNumber = (val: number) => {
  const decimalCount = val.toFixed().length - 1;
  const suffixIndex = Math.floor(decimalCount / 3);
  const suffix = ['', 'k', 'M', 'B', 'T'][suffixIndex];

  if (!suffix) return val.toFixed();

  const number = (
    val / Math.pow(10, Math.floor(decimalCount / 3) * 3)
  ).toFixed();

  return number + suffix;
};
